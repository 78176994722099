<template>
<div>
  <div class="full-width hero">
    <div class="col-7 only-mobi">
      <img :src="configuration.banner1.image" class="cover-img" />
    </div>
    <div class="col-6 center-content">
      <h1 class="align-center">{{configuration.banner1.title}}</h1>
      <p>{{configuration.banner1.content}}</p>
      <router-link v-if="configuration.banner1.link.length && !/^https?:\/\//.test(configuration.banner1.link)" :to="configuration.banner1.link" class="button">{{configuration.banner1.button}}</router-link>
      <a :href="configuration.banner1.link" class="button" v-if="configuration.banner1.link.length && /^https?:\/\//.test(configuration.banner1.link)" target="_blank">{{configuration.banner1.button}}</a>
    </div>
    <div class="col-6 no-mobi">
      <img :src="configuration.banner1.image" class="cover-img" />
    </div>
  </div>

  <div class="full-width gray-spacer free-shipping">
    <span>Spedizione gratuita da 59&euro;</span>
  </div>

  <div class="full-width hero lesser">
    <div class="col-7">
      <img :src="configuration.banner2.image" class="cover-img" />
    </div>
    <div class="col-5 center-content">
      <div class="button-square m-b-5">{{configuration.banner2.squareText}}</div>
      <p class="little-home">{{configuration.banner2.content}}</p>
      <router-link :to="configuration.banner2.link" class="button" v-if="configuration.banner2.link.length && !/^https?:\/\//.test(configuration.banner2.link)">{{configuration.banner2.button}}</router-link>
      <a :href="configuration.banner2.link" class="button" v-if="configuration.banner2.link.length && /^https?:\/\//.test(configuration.banner2.link)" target="_blank">{{configuration.banner2.button}}</a>
    </div>
  </div>

  <ProductSlider code="VETRINA" />

  <div class="full-width spacer no-mobi"></div>
  <div class="buttons full-width">
    <router-link to="/category/EC61000/alimentari" class="cust-btn to-1">ALIMENTARI</router-link>
    <router-link to="/category/EC62000/cosmesi" class="cust-btn to-2">COSMESI</router-link>
    <router-link to="/category/EC64000/bomboniere" class="cust-btn to-3">BOMBONIERE</router-link>
    <router-link to="/category/EC63000/casa" class="cust-btn to-4">CASA</router-link>
    <router-link to="/category/EC65000/moda" class="cust-btn to-5">MODA</router-link>
    <router-link to="/category/EC66000/outlet" class="cust-btn to-6">OUTLET</router-link>
  </div>
  <div class="full-width spacer no-mobi"></div>

  <!--<div class="full-width gray-spacer"></div>

  <div class="full-width big-banner">
    <div class="col-5 center-content">
      <div class="button-square m-b-5">Filiera pomodoro</div>
      <p class="big-p">Il nostro pomodoro<br/><b>non piace ai caporali.</b></p>
      <a :href="$config.contentUrl + 'filiere/pomodoro/'" class="button">scopri perch&eacute;</a>
    </div>
  </div>
  <div class="full-width gray-spacer"></div>-->

  <div class="full-width gray-spacer"></div>
  <div class="full-width hero lesser">
    <div class="col-7">
      <img :src="configuration.banner3.image" class="cover-img" />
    </div>
    <div class="col-5 center-content">
      <div class="button-square m-b-5">{{configuration.banner3.squareText}}</div>
      <p>{{configuration.banner3.title}}</p>
      <p class="little-home">{{configuration.banner3.content}}</p>
      <router-link :to="configuration.banner3.link" class="button" v-if="configuration.banner3.link.length && !/^https?:\/\//.test(configuration.banner3.link)">{{configuration.banner3.button}}</router-link>
      <a :href="configuration.banner3.link" class="button" v-if="configuration.banner3.link.length && /^https?:\/\//.test(configuration.banner3.link)" target="_blank">{{configuration.banner3.button}}</a>
    </div>
  </div>
  <div class="full-width gray-spacer"></div>

  <div class="full-width spacer no-mobi"></div>

  <div class="full-width">
    <div class="container m-am">
      <h3 class="neue">Mondo Altromercato</h3>
    </div>
  </div>

  <div class="full-width mondo-altromercato">
    <div class="container">
      <div class="external-link-image">
        <img src="@/assets/external/produttori.png" />
        <a :href="$config.contentUrl + 'produttori/'" class="neue">
          Produttori
          <img src="@/assets/arrow.png" />
        </a>
      </div>

      <div class="external-link-image">
        <img src="@/assets/external/negozi.png" />
        <a :href="$config.contentUrl + 'i-negozi-altromercato/'" class="neue">
          Negozi
          <img src="@/assets/arrow.png" />
        </a>
      </div>

      <div class="external-link-image">
        <img src="@/assets/external/sostenibilita.png" />
        <a :href="$config.contentUrl + 'sostenibilita-agita/'" class="neue">
          Sostenibilit&agrave;
          <img src="@/assets/arrow.png" />
        </a>
      </div>
    </div>
  </div>
  <div class="full-width spacer no-mobi"></div>
</div>
</template>

<script>
import axios from 'axios'
import ProductSlider from '@/components/ProductSlider'

export default {
  name: 'Home',
  components: {
    ProductSlider
  },

  data: function () {
    return {
      configuration: ''
    }
  },

  async created () {
    let resp = await axios.get('https://shop.altromercato.it/cms/simplestore/vetrina.php')
    this.configuration = resp.data
  }
}
</script>

<style >
  @media(max-width:768px){
    .slick-next {
      right: 40px;
    }
    .slick-prev {
        left: 40px;
        z-index: 1;
    }
    .little-home {
      font-size: 18px;
      line-height: 140%;
      max-width: 80%;
    }
  }
  @media (max-width:680px) {
    .hero .button{
      font-size: 14px;
    }
    .hero p{
      margin-top: 15px!important;
    }
  }
</style>
