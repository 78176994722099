// from incluso, to escluso

export default [
  {
    "code": "Promo Bevande",
    "percent": 20,
    "categories": ["EC71500"],
    "minProducts": 6,
    "from": "2024-07-24",
    "to": "2024-08-19"
  },
  {
    "code": "Promo Detergenza",
    "percent": 15,
    "categories": ["EC82005"],
    "minProducts": 2,
    "from": "2024-07-24",
    "to": "2024-08-19"
  },
]

