import axios from 'axios'
import config from './config'
import promo from './mockups/promo'
import producers from './mockups/producers'
import moment from 'moment'

export default {

  async loadCategories () {
    try {
      let cats = await axios.get(config.cmsUrl + 'categories.php')
      window.shop_categories = cats.data
    } catch (e) {
      window.shop_categories = []
    }
  },

  async loadFourthLevel () {
    try {
      let fourthLevel = await axios.get(config.cmsUrl + '2/filtri_quarto_livello')
      window.shop_fourthLevel = fourthLevel.data
    } catch (e) {
      window.shop_fourthLevel = []
    }
  },

  async loadProducts () {
    try {
      let cats = await axios.get(config.cmsUrl + 'products.php')

      cats.data.sort((a, b) => {
        return a.primary < b.primary ? 1 : -1
      })


      // Temporary promo bomboniere
      const confetti = cats.data.find(item => item.itemNumber === '00000388');
      if(confetti) {
        confetti.categories.push({
          categoryCode: "confetti",
          categoryName: "confetti Promo Bomboniere",
          depth: 4,
          language: "IT",
          parentProductCategoryCode: "",
          productCategoryHierarchyName: "ALBERO E-COMMERCE",
          visible: false
        });
      }

      window.shop_products = cats.data
    } catch (e) {
      window.shop_products = []
    }
  },

  async loadProduttori () {
    window.shop_produttori = producers
  },

  getCategory (code) {
    for (let index = 0; index < window.shop_categories.length; index++) {
      if (window.shop_categories[index].categoryCode == code) {
        return window.shop_categories[index]
      }
    }

    return {}
  },

  getSecondCategory (code) {
    let cat = false

    for (let index = 0; index < window.shop_categories.length; index++) {
      if (window.shop_categories[index].categoryCode == code) {
        cat = window.shop_categories[index]
      }
    }

    if (cat.depth == 2) {
      return cat
    } else {
      return this.getSecondCategory(cat.parentProductCategoryCode)
    }
  },

  getCategoryTree(code) {
    let cat
    for(let index = 0; index < window.shop_categories.length; index++) {
      if(window.shop_categories[index].categoryCode == code) {
        cat = window.shop_categories[index]
        break
      }
    }
    if(cat.depth == 2) {
      return [cat]
    } else {
      const cats = this.getCategoryTree(cat.parentProductCategoryCode)
      cats.unshift(cat)
      return cats
    }
  },

  getSecondLevel (code) {
    let res = []

    for (let index = 0; index < window.shop_categories.length; index++) {
      if (window.shop_categories[index].parentProductCategoryCode == code && window.shop_categories[index].visible) {
        res.push(window.shop_categories[index])
      }
    }

    if (['EC74000', 'EC74100', 'EC74200'].includes(code)) {
      res.sort((a, b) => {
        return a.categoryName > b.categoryName ? -1 : 1
      })
    }

    return res
  },

  getFourthLevel(cats) {
    const fourthLevels = []
    if(!Array.isArray(cats)) {
      cats = [cats]
    }

    cats.forEach(cat => {
      (window.shop_fourthLevel[cat] ?? []).forEach(fourthLevel => fourthLevels.push({...fourthLevel, open: false}))
    })
/*
    fourthLevels.forEach(first => {
      let first_enabled = false
      first.figli.forEach(second => {
        //second.enabled = second.prodotti.length && this.getAllProducts(first.categoria, false, '', false, false, false, second.prodotti).length
        second.enabled = second.prodotti.length
        if(second.enabled) {
          first_enabled = true
        }
      })
      first.enabled = first_enabled
    })
  */
    return fourthLevels
  },

  getMenuFourthLevel(menucats) {
    const MenufourthLevel = this.getFourthLevel(menucats);

    MenufourthLevel.forEach(first => {
      first.enabled = true
      first.figli.forEach(second => {
        second.enabledmenu = true
      })
      first.figli.sort((a, b) => {
        const aa = a.nome.toLowerCase();
        const bb = b.nome.toLowerCase();
        if(aa > bb) {
          return 1;
        }
        if(aa < bb) {
          return -1;
        }
        return 0;
      });
    })
    return MenufourthLevel
  },

  getCorrectName (name) {
    return name.trim().substr(name.trim().indexOf(" ") + 1)
  },

  capitalize (str) {
    if (!str) return ''

    str = str.trim()
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  getSlug (name) {
    return name.toLowerCase().replace(/ /g, '-').replace(/-+/g, '-').replace(/[^0-9a-zA-Z-]/g, '')
  },

  getCategoryPath (code) {
    let current = ''
    let res = []

    for (let index = 0; index < window.shop_categories.length; index++) {
      let cat = window.shop_categories[index]

      if (cat.categoryCode == code) {
        current = cat
        break
      }
    }

    res.push({
      label: this.getCorrectName(current.categoryName),
      path: '/category/' + current.categoryCode + '/' + this.getSlug(this.getCorrectName(current.categoryName))
    })

    if (current.depth > 2) {
      return res.concat(this.getCategoryPath(current.parentProductCategoryCode))
    }

    return res
  },

  getProductsVetrina () {
    return window.shop_products.filter((itm) => {
      return itm.vetrina
    })
  },

  getAllProducts(code, isBrand, query, filtriTag, escludiEsauriti, soloPromozione, prodottiFiltrati) {

    let res = []

    window.shop_products.forEach((item) => {
      if (!item.visible) {
        return true
      }
      if (!isBrand) {
        let isPushed = false

        item.categories.forEach((cat) => {
          if ((cat.categoryCode == code || !code) && !isPushed) {
            res.push(item)
            isPushed = true
          }
        })
      } else {
        if (item.brand == code || !code) {
          res.push(item)
        }
      }
    })

    if (query && query.length) {
      res = res.filter((itm) => {
        return itm.productName.toLowerCase().indexOf(query.toLowerCase()) != -1 ||
            itm.itemNumber.toLowerCase().indexOf(query.toLowerCase()) != -1;
      })
    }

    res = res.filter((itm) => {
      return !itm.masterCode || itm.masterCode == itm.itemNumber
    })

    res = res.filter((itm) => {
      return itm.visible
    })

    if(filtriTag) {
      filtriTag.forEach(filtroTag => {
        res = res.filter((item) => {
          return item.tags.includes(filtroTag)
        })
      })
    }

    if (escludiEsauriti) {
      res = res.filter((item) => {
        return item.stock > 0 && item.acquistabile
      })
    }

    if (soloPromozione) {
      res = res.filter((item) => {
        return item.sconto
      })
    }

    if(prodottiFiltrati && prodottiFiltrati.length) {
      res = res.filter((item) => {
        return prodottiFiltrati.includes(item.itemNumber)
      })
    }

    return res
  },

  getProduct (code) {
    for (let index = 0; index < window.shop_products.length; index++) {
      let item = window.shop_products[index]
      if (item.itemNumber == code) {
        return item
      }
    }
  },

  isModa (code) {
    let prod = this.getProduct(code)
    let moda = false

    prod.categories.forEach((c) => {
      if (c.categoryCode == 'EC65000') {
        moda = true
      }
    })

    return moda
  },

  isGift (code) {
    return ['00004388','00004389','00004390','00004391','00004392'].includes(code)
  },

  isMouseover (code) {
    let prod = this.getProduct(code)
    let moda = false
    let cats = ['EC63000', 'EC65000', 'EC71700', 'EC81700', 'EC81701', 'EC81702', 'EC81703', 'EC81704', 'EC64000', 'EC62000', 'EF00017']

    prod.categories.forEach((c) => {
      if (cats.includes(c.categoryCode)) {
        moda = true
      }
    })

    return moda
  },

  isAbbigliamento (code) {
    let prod = this.getProduct(code)
    let abbigliamento = false

    prod.categories.forEach((c) => {
      if (c.categoryCode == 'EC75000' || c.categoryCode == 'EC76400' || c.categoryCode == 'EC75400') {
        abbigliamento= true
      }
    })

    return abbigliamento
  },

  getRandom (arr, n) {
    if (n > arr.length) n = arr.length

    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  },

  /*getRelated (relatedCodes) {
    let related = []

    relatedCodes.forEach((item) => {
      window.shop_products.forEach((item2) => {
        if (item2.itemNumber == item.itemNumber) {
          related.push(item2)
        }
      })
    })

    return related
  },*/

  getRandomFromCatCode (code, excludes, relatedP, maxProds) {
    excludes = excludes || []
    relatedP = relatedP || []
    maxProds = maxProds || 8

    let all = this.getAllProducts(code)

    all = all.filter((itm) => {
      return !excludes.includes(itm.itemNumber)
    })

    if (!relatedP || typeof relatedP === 'boolean') {
      relatedP = []
    }

    let newRelated = []

    relatedP.forEach((itm) => {
      if (itm.visible) {
        newRelated.push(itm)
      }
    })

    return newRelated.concat(this.getRandom(all, Math.max(0, maxProds - relatedP.length)))
  },

  getRandomFromSupplierCode (code) {
    let all = this.getAllProducts()

    all = all.filter((itm) => {
      return itm.fairTradeSuppliers.includes(code)
    })

    return this.getRandom(all, 8)
  },

  formatBulletPoint (value) {
    let val = value.trim()
    return val.charAt(0).toUpperCase() + val.toLowerCase().slice(1);
  },

  getProductPrice (item, noDiscount, scontoDipendenti) {
    let price = item.productPrice
    if(!noDiscount) {
      if(item.sconto) {
        price = (item.productPrice * (100 - item.sconto)) / 100
        price = parseFloat((Math.floor(price * 10) / 10).toFixed(2))
      } else if(scontoDipendenti && item.scontoDipendenti) {
        price = (item.productPrice * (100 - item.scontoDipendenti)) / 100
        price = parseFloat((Math.floor(price * 10) / 10).toFixed(2))
      }
    }
    return price
  },

  hasDiscount (item) {
    return item.sconto
  },

  getScontoDipendenti(item) {
    const cats = item.item.categories.map(cat => cat.categoryCode)
    // from included, to excluded
    if(moment().isBetween('2024-02-23', '2024-03-09')) {
      if(cats.includes('EC61000')) { // ALIMENTARI
        return 30 + 10;
      }
      if(cats.includes('EC64000')) { // BOMBONIERE
        return 40 + 10;
      }
      if(cats.includes('EC63000')) { // CASA
        return 40 + 10;
      }
      if(cats.includes('EC62000')) { // COSMESI
        return 45 + 5;
      }
    } else {
      if(cats.includes('EC61000')) {
        return 30;
      }
      if(cats.includes('EC64000')) {
        return 40;
      }
      if(cats.includes('EC63000')) {
        return 40;
      }
      if(cats.includes('EC62000')) {
        return 45;
      }
    }
    return 0;
  },

  getSuppliers (codes) {
    let res = []
    codes.forEach((item) => {
      window.shop_produttori.forEach((itm) => {
        if (itm.codice.trim() == item.trim()) {
          res.push(itm)
        }
      })
    })

    if (codes.includes('EQ')) {
      res.push({
        paese:"Italia",
        titolo:"Equomercato",
        URL:"https://shop.altromercato.it/equo-mercato/",
        codice:"EQ"
      })
    }

    if (codes.includes('AQ')) {
      res.push({
        paese:"Italia",
        titolo:"Altraqualità",
        URL:"https://shop.altromercato.it/altra-qualita",
        codice:"AQ"
      })
    }

    if (codes.includes('M361')) {
      res.push({
        paese:"Italia",
        titolo:"Meridiano 361",
        URL:"https://shop.altromercato.it/meridiano",
        codice:"M361"
      })
    }

    if (codes.includes('9815390')) {
      res.push({
        paese:"Italia",
        titolo:"QUID",
        URL:"https://shop.altromercato.it/quid",
        codice:"9815390"
      })
    }

    if (codes.includes('9816334')) {
      res.push({
        paese:"Italia",
        titolo:"La Venenta",
        URL:"https://shop.altromercato.it/la-venenta",
        codice:"9816334"
      })
    }

    return res
  },


  getMultipleArray (item) {
    let multiplo = item.multiplo ? item.multiplo : 1
    let res = []

    for (let index = 1; index < 11; index++) {
      if ((index * multiplo) <= parseInt(item.stock)) {
        res.push(index * multiplo)
      }
    }

    return res
  },

  getRandomProducts (cats, inCart) {
    let toRandomize = window.shop_products.filter((item) => {
      return item.visible && cats.includes(item.productCategory) && !inCart.includes(item.itemNumber) && !item.masterCode.length
    })

    return this.getRandom(toRandomize, 2)
  },

  getByMastercode (masterCode) {
    return window.shop_products.filter((item) => {
      return item.masterCode == masterCode
    })
  },

  orderTotal (order) {
    let subTotal = 0
    let sconto = 0
    let total = 0

    order.cart.forEach((item) => {
      subTotal += this.getProductPrice(item.item) * item.qty
    })

    subTotal = subTotal.toFixed(2)

    if (order.discount) {
      sconto = ( subTotal / 100 ) * order.discount.percent
    }

    total = (parseFloat(subTotal) + parseFloat(order.amJson.markups[0].markupValue)).toFixed(2) - sconto.toFixed(2)
    total = total.toFixed(2)
    return (total + "").replace('.', ',')
  },

  formattedDate (date) {
    return date.substr(8, 2) + '-' + date.substr(5, 2) + '-' + date.substr(0, 4)
  },

  getTextStatus (orderStatus) {
    let text = 'In Attesa'

    switch (orderStatus) {
      case 1:
        text = 'Spedito'
        break
      case 2:
        text = 'Annullato'
        break
      case 3:
        text = 'Annullato'
        break
      case 4:
        text = 'In Lavorazione'
        break
    }

    return text
  },

  getPromos (cart) {
    let promos = []

    // Temporary promo bomboniere
    if(moment().isBetween("2024-07-15", "2024-09-01")) {
      let spesa = 0
      cart.forEach((item) => {
        if (item.item.categories.filter((it) => ["EC64000"].includes(it.categoryCode)).length) {
          spesa += this.getProductPrice(item.item) * parseFloat(item.qty)
        }
      })
      if(spesa >= 50) {
        let haspromobomboniere = false
        cart.forEach((item) => {
          if (item.item.categories.filter((it) => ["confetti"].includes(it.categoryCode)).length) {
            haspromobomboniere = true
          }
        })
        if(haspromobomboniere) {
          promos.push({
            "code": "Promo Bomboniere",
            "percent": 10,
            "categories": ["confetti"],
          });
        }
      }
    }

    promo.forEach((pc) => {
      let cats = pc.categories
      let min = pc.minProducts
      let currQty = 0
      let specific = pc.oneSpecific ? pc.oneSpecific : []
      let hasSpecific = pc.oneSpecific ? false : true

      if (pc.from && pc.to) {
        if (!moment().isBetween(pc.from, pc.to)) {
          return false
        }
      }

      cart.forEach((item) => {
        if (item.item.categories.filter((it) => cats.includes(it.categoryCode)).length) {
          currQty += parseInt(item.qty)
        }

        if (item.item.categories.filter((it) => specific.includes(it.categoryCode)).length) {
          hasSpecific = true
        }
      })

      if (currQty >= min && hasSpecific) {
        promos.push(pc)
      }
    })

    return promos
  },

  getTotalInPromo (cart) {
    let total = 0

    // Temporary promo bomboniere
    if(moment().isBetween("2024-07-15", "2024-09-01")) {
      let spesa = 0
      cart.forEach((item) => {
        if (item.item.categories.filter((it) => ["EC64000"].includes(it.categoryCode)).length) {
          spesa += this.getProductPrice(item.item) * parseFloat(item.qty)
        }
      })
      if(spesa >= 50) {
        cart.forEach((item) => {
          if (item.item.categories.filter((it) => ["confetti"].includes(it.categoryCode)).length) {
            total += this.getProductPrice(item.item) * parseFloat(item.qty)
          }
        })
      }
    }

    promo.forEach((pc) => {
      let cats = pc.categories
      let min = pc.minProducts
      let currQty = 0
      let localTotal = 0

      cart.forEach((item) => {
        if (item.item.categories.filter((it) => cats.includes(it.categoryCode)).length) {
          currQty += parseInt(item.qty)
          localTotal += this.getProductPrice(item.item) * parseFloat(item.qty)
        }
      })

      if (currQty >= min) {
        total += localTotal
      }
    })

    return total
  },

  hasPromo (cart) {
    return this.getPromos(cart).length
  },

  getPromoDiscount (cart) {
    let res = 0
    this.getPromos(cart).forEach((promo) => {
      cart.forEach(item => {
        if(item.item.categories.map(cat => cat.categoryCode).filter(cat => promo.categories.includes(cat)).length) {
          res += this.getProductPrice(item.item) * item.qty * promo.percent / 100
        }
      })
    })
    return res
  },

  getCatDiscount (cart, cat, percent) {
    let scontoModa = 0

    cart.forEach((item) => {
      if (item.item.categories.filter((ccc) => ccc.categoryCode == cat).length) {
        scontoModa += (this.getProductPrice(item.item) / 100) * parseInt(percent)
      }
    })

    return scontoModa
  },

  objSize (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (key) {
        size++
      }
    }
    return size;
  },

  async orderCat (code) {
    let categoryOrder = await axios.get('https://shop.altromercato.it/cms/simplestore/getOrder.php?category=' + code)
    let modaCodes = []

    if (this.objSize(categoryOrder.data)) {

      for (let index in categoryOrder.data) {
        modaCodes.push({
          code: index,
          order: parseInt(categoryOrder.data[index])
        })
      }

      modaCodes.sort(function(a, b) {
        return parseInt(a.order) - parseFloat(b.order);
      });
    }

    return modaCodes
  },

  async getCategoryOrder (code) {
    let categoryOrder = await axios.get('https://shop.altromercato.it/cms/simplestore/getOrder.php?category=' + code)
    let tmpItems = this.getAllProducts(code, false, false)

    if (this.objSize(categoryOrder.data)) {
      let modaCodes = []

      for (let index in categoryOrder.data) {
        modaCodes.push({
          code: index,
          order: parseInt(categoryOrder.data[index])
        })
      }

      modaCodes.sort(function(a, b) {
        return parseInt(a.order) - parseFloat(b.order);
      });

      let tmp = []
      modaCodes.forEach((modaC) => {
        for (let index = 0; index < tmpItems.length; index++) {
          if (tmpItems[index].itemNumber == modaC.code) {
            tmp.push(tmpItems[index])
            tmpItems.splice(index, 1)
            break
          }
        }
      })

      tmpItems = tmp.concat(tmpItems)
    }

    return tmpItems
  },

  under30Min (orderDate) {
    let orderEffective = orderDate.replace('.781Z', '')
    let toParse = moment(orderEffective.replace('T', ' '))
    let now = moment()
    let diff = now.diff(toParse, 'seconds')
    return diff < (60 * 30)
  },

  getRelated (relateds) {
    let res = []

    for (let rel of relateds) {
      for (let item of window.shop_products) {
        if (item.itemNumber == rel) {
          res.push(item)
          break;
        }
      }
    }

    return res
  },

  async checkCart(Vue) {
    let cartChanged = false

    if(Vue.$store.state.discount)
      if(
        Vue.$store.state.discount.end &&
        Vue.$store.state.discount.end.match(/^\d{4}-\d\d-\d\d$/) &&
        (new Date()).toISOString().replace(/-|T.*$/g, '') > Vue.$store.state.discount.end.replace(/-/g, '')
      ) {
        Vue.$store.commit('removeCoupon')
        cartChanged = true
      } else {
        let codesUrl = 'https://shop.altromercato.it/cms/codes_2.php'
        if(Vue.$config.backendUrl === 'https://shop.altromercato.it/staging/') {
          codesUrl = 'https://shop.altromercato.it/cms_staging/codes_2.php'
        }
        const res = await axios.get(codesUrl, { params: { email: Vue.$store.state.user ? Vue.$store.state.user.data.email : '' } })
        if(!res.data.find(coupon => coupon.code === Vue.$store.state.discount.code)) {
          Vue.$store.commit('removeCoupon')
          cartChanged = true
        }
      }

    await this.loadProducts()

    Vue.$store.state.cart.forEach(cartItem => {
      const catalogItem = this.getProduct(cartItem.item.itemNumber)
      if(!catalogItem || !catalogItem.visible || !catalogItem.acquistabile || catalogItem.stock < cartItem.qty) {
        Vue.$store.commit('removeItem', cartItem.item.itemNumber)
        cartChanged = true
        return
      }
      if((cartItem.item.productPrice != catalogItem.productPrice) || (cartItem.item.sconto != catalogItem.sconto)) {
        Vue.$store.commit('replaceItemInCart', {item: catalogItem})
        cartChanged = true
      }
    })

    if(cartChanged) {
      // eslint-disable-next-line no-empty
      try { Vue.$router.replace('/cart'); } catch (e) { }
      Vue.$toast.open('Il carrello è stato aggiornato, verifica l\'ordine prima di procedere.')
    }
    return !cartChanged
  }
}
